import svgMap from 'svgmap';
import Address from '@/models/Address';

import {
  SBX_FORMAT_FIELDS,
  SBX_ADDRESS_FIELD_NAMES,
  SBX_LINE_FIELDS,
  parseCustomAttributes,
} from '@/utils/sbxFieldUtils';

export function reduceJson(map) {
  if (!map) {
    return new Map();
  }

  let reduced = new Map();
  Object.keys(map).forEach((key) => {
    if (map[key]) {
      if (typeof map[key] === 'object') {
        let mapped = reduceJson(map[key]);
        if (!!mapped && mapped.size > 0) {
          reduced.set(key, Object.fromEntries(mapped));
        }
      } else {
        reduced.set(key, map[key]);
      }
    }
  });

  return reduced;
}

export function createSvgMap(countries) {
  return new svgMap({
    targetElementID: 'test-deck-map',
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}

export function getSortColumnName(columnName) {
  const ucName = columnName.toUpperCase();
  if (ucName.startsWith('INVOICE_SHIP_')) {
    return ucName.split('INVOICE').pop();
  }

  return ucName.split('INVOICE.').pop();
}

export function formatCurrency(amount, invoice, line) {
  const num = Number.parseFloat(`${amount}`);
  if (isNaN(num)) {
    return '--';
  }

  let currency = invoice['INVOICE.CURRENCY_CODE'];
  if (line['LINE.CURRENCY_CODE']) {
    currency = line['LINE.CURRENCY_CODE'];
  }

  const locale = navigator.language || navigator.userLanguage || 'en-US';
  if (currency) {
    return Intl.NumberFormat([locale], {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
    }).format(num);
  } else {
    return Intl.NumberFormat([locale], {
      minimumFractionDigits: 2,
    }).format(num);
  }
}

export function mapInvoiceAddresses(trxId, trxProps) {
  return Object.keys(SBX_ADDRESS_FIELD_NAMES).reduce((prv, key) => {
    const prop = `INVOICE.${key}`.toUpperCase();
    if (trxProps[prop]) {
      const addrMap = trxProps[prop];
      const address = Address.fromOneSource(addrMap, `${trxId}_${key}`, prop);
      prv.push(address);
    }
    return prv;
  }, []);
}

export function mapInvoiceAddress(trxId, address) {
  return Address.fromOneSource(
    address,
    `addr_${trxId}_${address.name}`,
    address.name,
  );
}

function flattenReg(value) {
  if (!value) {
    return null;
  }

  return (value || []).join(', ');
}
export function mapTransaction(
  transaction,
  formatMessage = (i18nKey, value) => `${i18nKey}.${value}`,
) {
  const activeLineFields = new Set();
  const addresses = (transaction.addresses || []).map((a) => {
    return Object.freeze(mapInvoiceAddress(transaction.id, a));
  });

  const row = {
    id: transaction.id,
    lineCount: transaction.lineCount,
    engineType: transaction.engineType || transaction.taxEngineType,
    ...transaction.document.documentProperties,
    'INVOICE.INVOICE_DATE':
      transaction.invoiceDate || transaction.transactionDate || '--',
    'INVOICE.INVOICE_NUMBER': transaction.invoiceNumber,
    versionRestriction: transaction.versionRestriction,
    _addresses: addresses,
    _expectations: transaction.document.expectations || [],
    _lines: transaction.lines.map((line) => {
      line.activeFields.forEach((f) => activeLineFields.add(f));
      return mapLine(transaction, line, formatMessage);
    }),
  };

  ['SELLER_ROLE', 'BUYER_ROLE', 'MIDDLEMAN_ROLE'].forEach((k) => {
    const key = `INVOICE.REGISTRATIONS.${k}`;
    if (row[key]) {
      row[key] = flattenReg(row[key]);
    }
  });

  addresses.forEach((a) => {
    row[a.fieldPath.replace('.', '_')] = a;

    //row[a.fieldPath] = a;
  });

  row._lineFields = SBX_LINE_FIELDS.filter((f) =>
    activeLineFields.has(f.key),
  ).map((f) => {
    return {
      ...f,
      sorter: false,
    };
  });

  (transaction.document.expectations || []).forEach((exp) => {
    row[exp.name] = exp.value;
  });

  return row;
}

export function mapLine(
  transaction,
  line,
  formatMessage = (i18nKey, value) => `${i18nKey}.${value}`,
) {
  const rowLine = {
    key: `${transaction.id}_${line.lineNumber}`,
    ...line,
  };

  rowLine.grossAmount = formatCurrency(
    rowLine.grossAmount,
    transaction.document.documentProperties,
    line,
  );

  rowLine.addresses = line.addresses.map((a) => {
    const addr = mapInvoiceAddress(rowLine.key, a);
    return {
      ...addr,
      label: formatMessage('addressType', addr.fieldName),
    };
  });

  const formatKeys = Object.keys(SBX_FORMAT_FIELDS);
  formatKeys.forEach((key) => {
    if (rowLine[key]) {
      rowLine[key] = formatMessage(SBX_FORMAT_FIELDS[key], rowLine[key]);
    }
  });

  rowLine.customAttributes = parseCustomAttributes(
    line.customAttributes,
    'LINE',
  );

  rowLine.expectations = [...(rowLine.expectations || [])].map(
    (expectation) => {
      if (SBX_FORMAT_FIELDS[expectation.name]) {
        const expValue = `${expectation.value}`.toUpperCase();

        return {
          ...expectation,
          value: formatMessage(SBX_FORMAT_FIELDS[expectation.name], expValue),
        };
      } else {
        return expectation;
      }
    },
  );

  ['SELLER_ROLE', 'BUYER_ROLE', 'MIDDLEMAN_ROLE'].forEach((k) => {
    const key = `LINE.REGISTRATIONS.${k}`;
    if (rowLine[key]) {
      rowLine[key] = flattenReg(rowLine[key]);
    }
  });

  return rowLine;
}

export function mapCountryData(shipToGeos, shipFromGeos) {
  const countries = Object.keys(shipToGeos || {}).reduce((map, key) => {
    const value = shipToGeos[key] || 0;
    if (!map[key]) {
      map[key] = {
        shipTo: value,
        shipFrom: 0,
        total: value,
      };
    } else {
      map[key].shipTo += value;
      map[key].total += value;
    }
    return map;
  }, {});

  Object.keys(shipFromGeos || {}).forEach((key) => {
    const value = shipFromGeos[key] || 0;
    if (!countries[key]) {
      countries[key] = {
        shipTo: 0,
        shipFrom: value,
        total: value,
      };
    } else {
      countries[key].shipFrom += value;
      countries[key].total += value;
    }
  });

  return countries;
}
