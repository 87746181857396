import hljs from 'highlight.js/lib/core';
import {
  SBX_INVOICE_FIELDS_MAP,
  SBX_INVOICE_ADDRESS_FIELD_KEYS,
  SBX_LINE_ADDRESS_FIELD_KEYS,
  SBX_LINE_FIELDS_MAP,
  parseCustomAttributes,
} from '@/utils/sbxFieldUtils';

export const IGNORED_ATTRIBUTES = new Set([
  ...[...SBX_INVOICE_ADDRESS_FIELD_KEYS].map((o) => o.toUpperCase()),
  ...[...SBX_LINE_ADDRESS_FIELD_KEYS].map((o) => o.toUpperCase()),
]);

import _ from 'lodash';

IGNORED_ATTRIBUTES.add('INVOICE.COMPANY_NAME');
IGNORED_ATTRIBUTES.add('INVOICE.INVOICE_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.INVOICE_DATE');
IGNORED_ATTRIBUTES.add('INVOICE.EXTERNAL_COMPANY_ID');
IGNORED_ATTRIBUTES.add('INVOICE.CURRENCY_CODE');
IGNORED_ATTRIBUTES.add('INVOICE.TRANSACTION_TYPE');
IGNORED_ATTRIBUTES.add('INVOICE.COMPANY_ROLE');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.BUYER_ROLE');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.SELLER_ROLE');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.MIDDLEMAN_ROLE');
IGNORED_ATTRIBUTES.add('INVOICE.CUSTOMER_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.CUSTOMER_NAME');
IGNORED_ATTRIBUTES.add('INVOICE.VENDOR_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.VENDOR_NAME');
IGNORED_ATTRIBUTES.add('LINE.LINE_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.COMPANY_ROLE');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.BUYER_ROLE');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.SELLER_ROLE');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.MIDDLEMAN_ROLE');
IGNORED_ATTRIBUTES.add('LINE.CUSTOMER_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.CUSTOMER_NAME');
IGNORED_ATTRIBUTES.add('LINE.VENDOR_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.VENDOR_NAME');
IGNORED_ATTRIBUTES.add('LINE.PRODUCT_CODE');
IGNORED_ATTRIBUTES.add('LINE.COMMODITY_CODE');
IGNORED_ATTRIBUTES.add('LINE.QUANTITY');
IGNORED_ATTRIBUTES.add('LINE.UOM');
IGNORED_ATTRIBUTES.add('LINE.GROSS_AMOUNT');

function flattenReg(value) {
  if (!value) {
    return null;
  }

  if (_.isArray(value)) {
    return value.join(', ');
  } else {
    return value;
  }
}

function getRegistrations(value, predicate = 'INVOICE') {
  return {
    buyer: flattenReg(
      value[`${predicate}.REGISTRATIONS.BUYER_ROLE`.toUpperCase()],
    ),
    seller: flattenReg(
      value[`${predicate}.REGISTRATIONS.SELLER_ROLE`.toUpperCase()],
    ),
    middleman: flattenReg(
      value[`${predicate}.REGISTRATIONS.MIDDLEMAN_ROLE`.toUpperCase()],
    ),
  };
}

function getLineCounterParty(value, registrations, companyRole) {
  const retval = {
    label: 'Customer',
    name: value.customerName || value['LINE.CUSTOMER_NAME'],
    number: value.customerNumber || value['LINE.CUSTOMER_NUMBER'],
    registration: registrations?.buyer,
  };

  if (companyRole === 'BUYER' || companyRole === 'B') {
    retval.label = 'Vendor';
    retval.name = value.vendorName || value['LINE.VENDOR_NAME'];
    retval.number = value.vendorNumber || value['LINE.VENDOR_NUMBER'];
    retval.registration = registrations?.seller;
  }

  if (retval.name || retval.number) {
    return retval;
  }

  return null;
}

function getCounterParty(value, registrations) {
  const companyRole = (
    value.companyRole || value['INVOICE.COMPANY_ROLE']
  )?.toUpperCase();
  const retval = {
    label: 'Customer',
    name: value.customerName || value['INVOICE.CUSTOMER_NAME'],
    number: value.customerNumber || value['INVOICE.CUSTOMER_NUMBER'],
    registration: registrations?.buyer,
  };

  if (companyRole === 'BUYER' || companyRole === 'B') {
    retval.label = 'Vendor';
    retval.name = value.vendorName || value['INVOICE.VENDOR_NAME'];
    retval.number = value.vendorNumber || value['INVOICE.VENDOR_NUMBER'];
    retval.registration = registrations?.seller;
  }

  if (retval.name || retval.number) {
    return retval;
  }

  return null;
}

function isBoolean(value) {
  if (value === true || value === false) {
    return true;
  }

  const strValue = `${value}`.toUpperCase().trim();
  return strValue === 'TRUE' || strValue === 'FALSE';
}

function parseLineAttributes(value) {
  const attrs = Object.keys(value)
    .filter((lineKey) => {
      const key = lineKey.toUpperCase();
      if (key.startsWith('INVOICE.') || key.startsWith('EXPECTED.INVOICE')) {
        return false;
      }

      if (IGNORED_ATTRIBUTES.has(key)) {
        return false;
      }

      if (key.includes('USER_ELEMENT') || key.includes('EXPECTED')) {
        return false;
      }

      let isAddressField = false;
      SBX_LINE_ADDRESS_FIELD_KEYS.forEach((k) => {
        if (key.includes(k)) {
          isAddressField = true;
          return false;
        }
      });

      return !isAddressField;
    })
    .map((key) => {
      const ucKey = key.toUpperCase();
      let fieldValue = value[ucKey];
      const field = SBX_LINE_FIELDS_MAP[ucKey];
      const label =
        field?.label || key.replace('LINE.', '').replaceAll('_', ' ');
      let fieldType = field?.fieldType || null;

      if (fieldType === 'BOOLEAN' || isBoolean(fieldValue)) {
        fieldType = 'BOOLEAN';
        fieldValue =
          fieldValue === true ||
          `${fieldValue}`.toUpperCase().trim() === 'TRUE';
      }

      return {
        key: ucKey,
        label: label.replace('Line ', ''),
        fieldType,
        value: fieldValue,
        fieldPath: ucKey,
      };
    });

  return attrs;
}

function parseLineExpectations(line) {
  return (line.expectations || []).map((e) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!e.hasOwnProperty('label')) {
      const field = SBX_LINE_FIELDS_MAP[e.name];
      return {
        name: e.name || field.label,
        label:
          field.label || e.name.replace('EXPECTED.LINE.').replace('.', ' '),
        value: e.value,
      };
    } else {
      return e;
    }
  });
}

function parseDocumentAttributes(value) {
  return Object.keys(value)
    .filter((invoiceKey) => {
      const key = invoiceKey.toUpperCase();
      if (!key.startsWith('INVOICE.') || key.startsWith('EXPECTED.INVOICE')) {
        return false;
      }

      if (IGNORED_ATTRIBUTES.has(key)) {
        return false;
      }

      if (key.includes('USER_ELEMENT')) {
        return false;
      }

      let isAddressField = false;
      SBX_INVOICE_ADDRESS_FIELD_KEYS.forEach((k) => {
        if (key.includes(k)) {
          isAddressField = true;
          return false;
        }
      });

      return !isAddressField;
    })
    .map((key) => {
      const ucKey = key.toUpperCase();
      let fieldValue = value[key];
      const field = SBX_INVOICE_FIELDS_MAP[ucKey];
      const label =
        field?.label ||
        key
          .replace('INVOICE.', '')
          .replace('invoice.', '')
          .replaceAll('_', ' ');

      let fieldType = field?.fieldType || null;

      if (fieldType === 'BOOLEAN' || isBoolean(fieldValue)) {
        fieldType = 'BOOLEAN';
        fieldValue =
          fieldValue === true ||
          `${fieldValue}`.toUpperCase().trim() === 'TRUE';
      }

      return {
        key,
        label,
        fieldType,
        value: fieldValue,
        fieldPath: key,
      };
    });
}

function parseLines(companyRole, lines) {
  const result = (lines || []).map((line) => {
    line.registrations = getRegistrations(line.lineProperties, 'line');
    line.hasRegistrations = !!(
      line.registrations.buyer ||
      line.registrations.middleman ||
      line.registrations.seller
    );

    line.counterParty = getLineCounterParty(
      line,
      line.registrations,
      companyRole,
    );

    line.hasCustomAttributes = line.customAttributes?.length > 0;
    line.lineAttributes = parseLineAttributes(line.lineProperties);
    line.hasLineAttributes = line.lineAttributes.length > 0;
    line.expectations = parseLineExpectations(line);
    line.hasLineExpectations = line.expectations.length > 0;

    if (!line.products) {
      line.products = [];
      if (line.lineProperties['LINE.PRODUCT_CODE']) {
        line.products.push({
          type: 'PRODUCT_CODE',
          code: line.lineProperties['LINE.PRODUCT_CODE'],
        });
      }

      if (line.lineProperties['LINE.COMMODITY_CODE']) {
        line.products.push({
          type: 'COMMODITY_CODE',
          code: line.lineProperties['LINE.COMMODITY_CODE'],
        });
      }
    }

    return line;
  });

  return result;
}

// function stripXMLNS(xml) {
//   if (xml) {
//     return xml.replace(/(\s)?xmlns:.*=".*">/, '>');
//   }

//   return xml;
// }

export function parseValue(value) {
  const registrations = getRegistrations(value);
  const counterParty = getCounterParty(value, registrations);
  const companyRole = value['INVOICE.COMPANY_ROLE']?.toUpperCase();

  return {
    registrations,
    counterParty,
    invoiceNumber: value['INVOICE.INVOICE_NUMBER'],
    externalCompanyId: value['INVOICE.EXTERNAL_COMPANY_ID'],
    companyRole,
    companyName: value['INVOICE.COMPANY_NAME'],
    transactionType: value['INVOICE.TRANSACTION_TYPE'],
    invoiceDate: value['INVOICE.INVOICE_DATE'] || 'Variable',
    titleTransfer: value['INVOICE.POINT_OF_TITLE_TRANSFER'],
    currencyCode: value['INVOICE.CURRENCY_CODE'],
    invoiceUserElements: parseCustomAttributes(value, 'INVOICE'),
    invoiceAddresses: value._addresses || value.addresses || [],
    documentAttributes: parseDocumentAttributes(value),
    documentLines: parseLines(
      companyRole,
      value._details?.lines || value._lines || [],
    ),
    //documentXML: stripXMLNS(value.documentXML),
  };
}

export function highlightXML(xmlText) {
  const result = hljs.highlight(xmlText, {
    language: 'xml',
    ignoreIllegals: true,
  });
  return result.value;
}
